import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import Logo from './logo'

const Header = () => (
  <header>
    <div className="Site--image-cover" />
    <ul className="Site--nav">
      <li><a href="mailto:codeisart.tech@gmail.com"><FontAwesomeIcon icon={faEnvelope} /> Contact</a></li>
    </ul>
    <Link to="/" className="Site--logo-link">
      <Logo />
    </Link>
    <h3 className="Site--header-tagline">Convert <span className="Site--tagline-emphasis">beautiful code</span> snippets to high resolution image files, ready to print.</h3>
  </header>
)

Header.propTypes = {}

Header.defaultProps = {}

export default Header
